import { getFragmentData } from '../../gql/__generated__';
import {
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsBuyFeaturePopupOpened,
  setPrevReelsPosition,
  setSlotConfig,
  setUserLastBetResult,
} from '../../gql/cache';
import client from '../../gql/client';
import { userBonusFragment } from '../../gql/fragment';
import { getBonuses, getReplayUserBonuses, getUserBonuses, userBonusBetsGql } from '../../gql/query';
import { EventTypes, eventManager } from '../../slotMachine/config';
import { freeRoundBonusId } from '../../slotMachine/config/bonusInfo';
import {
  calcActiveUserBonusTotalWinAmount,
  getGameModeByBonusId,
  getNonNullableValue,
  isBuyFeatureMode,
  isFreeSpinsMode,
  queryParams,
} from '../../utils';

export const useBonuses = () => {
  const getPurchasableBonuses = async () => {
    const bonusData = await client.query({
      query: getBonuses,
      variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
      fetchPolicy: 'network-only',
    });
    setBonuses(bonusData.data.bonuses);
  };
  const checkBrokenGameMain = async () => {
    const query = queryParams.has('replayBetId') ? getReplayUserBonuses : getUserBonuses;
    const activeUserBonusData = await client.query({
      query,
      variables: {
        input: { status: 'ACTIVE', slotId: setSlotConfig().id },
      },
      fetchPolicy: 'network-only',
    });

    // its locally for testing.
    // ------------------------------
    // const originActiveUserBonusData = await client.query({
    //   query: getUserBonuses,
    //   variables: { input: { status: 'ACTIVE', slotId: setSlotConfig().id } },
    //   fetchPolicy: 'network-only',
    // });
    // const activeUserBonusData: ApolloQueryResult<GetUserBonusesQuery> = JSON.parse(
    //   JSON.stringify(originActiveUserBonusData),
    // );

    // const frbDummyData = makeFragmentData(
    //   {
    //     ...setCurrentBonus(),
    //     id: freeRoundBonusId,
    //     bonusId: freeRoundBonusId,
    //     coinAmount: 1,
    //     coinValue: 100,
    //     rounds: 2,
    //     totalWinAmount: 10000,
    //   },
    //   userBonusFragment,
    // );

    // activeUserBonusData.data.userBonuses!.push(frbDummyData);
    // ------------------------------

    const userBonuses = getFragmentData(userBonusFragment, getNonNullableValue(activeUserBonusData.data.userBonuses));
    const buyFeatureBonus = userBonuses.find((ub) => isBuyFeatureMode(getGameModeByBonusId(ub.bonusId)));
    const freeSpinsBonus = userBonuses.find((ub) => isFreeSpinsMode(getGameModeByBonusId(ub.bonusId)));
    const freeRoundBonus = userBonuses.find((ub) => ub.bonusId === freeRoundBonusId);

    if (userBonuses.length > 0) {
      if (buyFeatureBonus) {
        const specialBonus = userBonuses.find((ub) => ub.bonus!.type === 'SPECIAL_ROUND');
        setBrokenBuyFeature(true);
        setIsBuyFeaturePopupOpened(true);
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
        setCurrentBonusId(userBonuses[0]!.id);
        if (specialBonus) {
          setCurrentBonus({
            ...specialBonus,
            isActive: true,
            currentRound: specialBonus.roundsPlayed,
            totalRound: specialBonus.rounds + specialBonus.roundsPlayed,
          });
        }
      }

      if (freeSpinsBonus) {
        setBrokenGame(true);
        const totalWin = calcActiveUserBonusTotalWinAmount(freeSpinsBonus);
        setCurrentFreeSpinsTotalWin(totalWin);
        setCurrentBonusId(freeSpinsBonus.id);

        const userBonusBetsData = await client.query({
          query: userBonusBetsGql,
          variables: {
            input: {
              filter: {
                userBonusId: freeSpinsBonus.id,
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        const lastBet = getNonNullableValue(setUserLastBetResult());
        setBrokenGameReelSet(lastBet.reelSetId);
        setPrevReelsPosition(lastBet.result.reelPositions);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: lastBet.data.features.gameRoundStore.playedFS,
          rounds: lastBet.data.features.gameRoundStore.countFS,
          totalRound: userBonusBetsData.data.bets?.pageInfo.count!,
          totalWinAmount: totalWin,
        });
      }

      if (freeRoundBonus && !buyFeatureBonus && (!freeSpinsBonus || freeSpinsBonus.data?.frbReferenceId)) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
        setFreeRoundBonus({
          id: freeRoundBonus.id,
          bonusId: freeRoundBonus.bonusId,
          coinAmount: freeRoundBonus.coinAmount!,
          coinValue: freeRoundBonus.coinValue!,
          status: freeRoundBonus.status,
          rounds: freeRoundBonus.rounds,
          totalWinAmount,
          isActive: true,
          currentRound: 0,
        });
        setFreeRoundsTotalWin(totalWinAmount);
      }
    }
  };
  return { getPurchasableBonuses, checkBrokenGameMain };
};
